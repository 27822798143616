<!--
 * @Author: wh
 * @Date: 2020-08-26 10:59:59
 * @LastEditTime: 2021-07-15 11:37:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\EBCHome\statisticalAnalysis\sourceAnalysis.vue
-->
<template>
    <div class="sourceAnalysis">
        <div class="time-con">
            <div class="time">
                <span>选择时间段：</span>
                <el-date-picker
                    v-model="value1"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    @change="changeTime"
                >
                </el-date-picker>
            </div>
            <div class="time">
                <span>货源地名称：</span>
                <el-input v-model="sourceName" placeholder="输入货源地名称">
                    <el-button
                        class="search-btn"
                        slot="append"
                        icon="el-icon-search"
                        @click="handleAreaStat"
                    ></el-button>
                </el-input>
            </div>
        </div>
        <div class="materials-classifiy">
            <ul>
                <li
                    v-for="(item, index) in materials"
                    :key="index"
                    class="item"
                    :class="{'active': index===activeIndex}"
                    @click="changeMaterials(item, index)"
                >
                    <span :class="`iconfont ${item.fontClass}`"></span>
                    <span>{{ item.name }}</span>
                </li>
            </ul>
        </div>
        <div class="chartMap" v-loading="loading">
            <div
                class="chartMap"
                id="chartMap"
                v-show="!isSingleSource"
            ></div>
            <div
                class="chartMap"
                id="singleChartMap"
                v-show="isSingleSource"
            ></div>
        </div>
    </div>
</template>

<script>
import '@/assets/js/echarts/map/js/china';
export default {
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            // 搜索货源地名称
            sourceName: '',
            value1: '',
            materials: [
                { name: '水泥', fontClass: 'iconshan', code: '202' },
                { name: '粉煤灰', fontClass: 'iconshuini1', code: '205-FMH' },
                { name: '矿粉', fontClass: 'iconshashizhonglei1x', code: '205-KZF' },
                { name: '外加剂', fontClass: 'iconshijiyanshou1', code: '208' },
                { name: '砂', fontClass: 'iconkuangchan', code: '203' },
                { name: '石', fontClass: 'iconshi', code: '204' },
                { name: '其他', fontClass: 'iconanalysis-chart', code: '0' },
            ],
            // 选中物料index
            activeIndex: 0,
            // 原材料编码
            typeCode: '202',
            // 开始时间
            beginTime: '',
            // 结束时间
            endTime: '',
            // 货源地数据
            sourceData: [],
            originalSourceData: [],
            // 是否是单个货源地视图
            isSingleSource: false,
            // 货源地球大小
            differArr: [],
            // 设定大小值
            sourceHeight: [30, 40, 50],
            fontSize: [20, 24, 28],
            groupData: [],
            // 货源地id
            sourceId: '',
            currentSource: {},
            singleSourceChartData1: [],
            singleSourceChartData2: [],
            // 货源地详情
            sourceDetail: {},
            detail: {},
            currentMaterial: { name: '水泥', fontClass: 'iconshuini', code: '202' },
        };
    },
    watch: {},
    computed: {},
    created() {
        window.sourceDetail = this.toDetail;
        window.sourceSupplyDetail = this.toSupplyDetails;
        window.sourceFlowAnalysis = this.toFlowAnalysis;
        window.sourceCloseSingal = this.closeSingal;
    },
    mounted() {

        this.init();
    },
    methods: {
        // 页面初始化
        init() {
            // 默认时间段为本年，1月1号到现在
            const date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month;
            let day = date.getDate();
            day = day < 10 ? '0' + day : day;
            this.value1 = [new Date(year, 0, 1), new Date()];
            this.beginTime = year + '-01-01';
            this.endTime = year + '-' + month + '-' + day;
            this.handleAreaStat();
        },
        // 详情
        toDetail() {
            this.$toast({
                title: false,
                type: 'eject',
                width: '7rem',
                height: '4.65rem',
                t_url: 'EBCHome/statisticalAnalysis/detailPop',
                viewPosition: 'view',
                extr: {
                    detailData: this.sourceDetail,
                },
            });
        },
        // 供货明细
        toSupplyDetails() {
            this.$toast({
                title: false,
                type: 'eject',
                t_url: 'EBCHome/statisticalAnalysis/supplyDetailsPop',
                viewPosition: 'view',
                extr: {
                    sourceId: this.sourceId,
                    typeCode: this.typeCode,
                    beginTime: this.beginTime,
                    endTime: this.endTime,
                },
            });
        },
        // 流向分析
        toFlowAnalysis() {
            this.$toast({
                title: false,
                type: 'eject',
                t_url: 'EBCHome/statisticalAnalysis/flowAnalysisPop',
                viewPosition: 'view',
                extr: {
                    sourceId: this.sourceId,
                    typeCode: this.typeCode,
                    beginTime: this.beginTime,
                    endTime: this.endTime,
                },
            });
        },
        // 切换时间
        changeTime(time) {
            if (time) {
                this.beginTime = time[0];
                this.endTime = time[1];
                this.handleAreaStat();
            }
        },
        // 切换原材料类型
        changeMaterials(data, index) {
            this.activeIndex = index;
            this.typeCode = data.code;
            this.currentMaterial = data;
            this.isSingleSource = false;
            this.handleAreaStat();
        },
        // 关闭单个货源地
        closeSingal() {
            this.isSingleSource = false;
        },
        // 处理货源地数据
        async handleAreaStat() {
            this.loading = true;
            const res = await this.getAreaStat();
            if (res) {
                this.originalSourceData = res;
                this.sourceData = [];
                const arr = [];
                res.map(item => {
                    if (item.x_coordinate_point && item.y_coordinate_point) {
                        const json = {
                            name: item.short_name || item.name,
                            value: [item.x_coordinate_point, item.y_coordinate_point, item.total_count],
                        };
                        arr.push(json);
                    }
                });
                this.sourceData = arr;
                const group = await this.getAreaGroup();
                if (group) {
                    this.groupData = group;
                    this.initChart();
                } else {
                    this.initChart();
                }
            }
            this.loading = false;
        },
        // 处理单个货源地数据
        async handleSupplyBase() {
            this.loading = true;
            const res = await this.getSupplyBase();
            const tenantInfo = await this.getTenantInfo(res.tenant_id);
            res.tenant_shortname = tenantInfo.tenant_shortname;
            if (res) {
                this.singleSourceChartData1 = [];
                this.singleSourceChartData2 = [];
                this.detail = res;
                const stations = await this.getStationxy(res.supply_station);
                if (stations) {
                    stations.map(item => {
                        this.singleSourceChartData1.push(
                            [
                                { coord: [Number(this.currentSource.x_coordinate_point), Number(this.currentSource.y_coordinate_point)] },
                                { coord: [item.longitude, item.latitude] },
                            ]
                        );
                        this.singleSourceChartData2.push(
                            {
                                name: item.name,
                                value: [item.longitude, item.latitude, 90],
                            }
                        );
                    });
                    this.linesChart();
                }
            }
            const detail = await this.getDetail();
            if (detail) {
                this.sourceDetail = detail;
            }
            this.loading = false;
        },

        // 获取所有标点位置处于中心的点
        getPosCenter(list) {
            let left = 0; let right = 0; let top = 0; let bottom = 0;
            if (list.length > 0) {
                list.forEach(item => {
                    if (!left) {
                        left = right = item.x_coordinate_point;
                    }
                    if (!top) {
                        top = bottom = item.y_coordinate_point;
                    }
                    if (item.x_coordinate_point && item.x_coordinate_point < left) {
                        left = item.x_coordinate_point;
                    }
                    if (item.x_coordinate_point && item.x_coordinate_point > right) {
                        right = item.x_coordinate_point;
                    }
                    if (item.y_coordinate_point && item.y_coordinate_point < top) {
                        top = item.y_coordinate_point;
                    }
                    if (item.y_coordinate_point && item.y_coordinate_point > bottom) {
                        bottom = item.y_coordinate_point;
                    }
                });
            }
            const centerx = Number(left) + (Number(right) - Number(left)) / 2;
            const centery = Number(top) + (Number(bottom) - Number(top)) / 2;
            return {
                min: [left, top],
                max: [right, bottom],
                center: [centerx, centery],
            };
        },
        // 根据经纬极值计算绽放级别。
        // eslint-disable-next-line max-params
        getZoom(minLat, minLng, maxLat, maxLng) {
            // eslint-disable-next-line max-len
            const zoom = [1, 20, 50, 75, 100, 150, 250, 375, 500, 600, 700, 1000, 1200, 1400, 1600, 2000, 3000, 4200, 5500];// 级别18到3。
            const distance = this.getDistance(minLng, minLat, maxLng, maxLat).toFixed(1); // 获取两点距离,保留小数点后两位
            for (let i = 0, zoomLen = zoom.length; i < zoomLen; i++) {
                if (zoom[i] - distance > 0) {
                    // return 18 - i + 3;// 之所以会多3，是因为地图范围常常是比例尺距离的10倍以上。所以级别会增加3。
                    return 18 - i - 1; //
                }
            }
            return 1;
        },
        // 经纬度转换成三角函数中度分表形式。
        rad(d) {
            return d * Math.PI / 180.0;
        },
        // 根据经纬度计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
        // eslint-disable-next-line max-params
        getDistance(lat1, lng1, lat2, lng2) {

            const radLat1 = this.rad(lat1);
            const radLat2 = this.rad(lat2);
            const a = radLat1 - radLat2;
            const b = this.rad(lng1) - this.rad(lng2);
            let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2)
        + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
            s = s * 6378.137; // EARTH_RADIUS;
            s = Math.round(s * 10000) / 10000; // 输出为公里

            const distance = s;
            // let distance_str = '';

            // // eslint-disable-next-line radix
            // if (parseInt(distance) >= 1) {
            //     distance_str = distance.toFixed(1) + 'km';
            // } else {
            //     distance_str = distance * 1000 + 'm';
            // }
            // s=s.toFixed(4);
            return s;
        },
        // 获取货源地
        getAreaStat() {
            const path = '/interfaceApi/procurement/source/get_area_stat?beginTime='
            + this.beginTime + '&endTime=' + this.endTime + '&typeCode=' + this.typeCode;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取原材料货源地分组统计
        getAreaGroup() {
            const path = '/interfaceApi/procurement/source/get_area_group?beginTime='
            + this.beginTime + '&endTime=' + this.endTime + '&typeCode=' + this.typeCode + '&groupCount=3&sourceName=' + this.sourceName;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取单个货源地信息
        getSupplyBase() {
            const path = '/interfaceApi/procurement/source/get_supply_base?beginTime='
            + this.beginTime + '&endTime=' + this.endTime + '&typeCode=' + this.typeCode + '&sourceId=' + this.sourceId;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取企业信息
        getTenantInfo(tenantId) {
            const path = '/interfaceApi/tenant/tenant/' + tenantId;
            return this.$axios.$get(path, { defEx: true });
        },
        // 拉取场站坐标
        getStationxy(stationIds) {
            const path = '/interfaceApi/baseinfo/stationmanger/get_coordinate_notoken?stationIds=' + stationIds;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取原材料货源地信息
        getDetail() {
            const path = '/interfaceApi/procurement/source/get_by_id/' + this.sourceId;
            return this.$axios.$get(path, { defEx: true });
        },
        // 初始化货源地图表
        initChart() {
            this.sourceOpt = {};
            const svgIcon = require('../images/' + this.materials[this.activeIndex].fontClass + '.png');
            const myChart = this.$echarts.init(document.getElementById('chartMap'));
            let centerx = '';
            let centery = '';
            let series = [];
            const geo = { // 这个是重点配置区
                map: 'china', // 表示中国地图
                label: {
                    normal: {
                        show: true, // 是否显示对应地名
                        textStyle: { // 字体颜色
                            color: '#00cad5',
                        },
                    },
                    emphasis: {
                        show: false,
                        textStyle: {
                            color: '#fff',
                        },
                    },
                },
                roam: true,
                itemStyle: {
                    normal: {
                        borderWidth: 1, // 地图边框宽度
                        borderColor: '#0c4765', // 地图边框颜色
                        areaColor: '#011e3e', // 地图颜色
                    },
                    emphasis: {// 选中省份的颜色
                        areaColor: '#011e3e',
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        shadowBlur: 0,
                        borderWidth: 1,
                        shadowColor: '#fff',
                    },
                },
                zoom: 2,
                // center: [centerx, centery],
            };
            myChart.off('click');
            myChart.off('georoam');
            let zoom = 1;
            if (this.sourceData.length > 0) {
                if (this.sourceName) {
                    for (const item of this.groupData) {
                        for (const v of item.items) {
                            // eslint-disable-next-line max-depth
                            if (v.x_coordinate_point && v.y_coordinate_point) {
                                centerx = v.x_coordinate_point;
                                centery = v.y_coordinate_point;
                                break;
                            }
                        }
                        if (centerx) {break;}
                    }
                    zoom = 16;
                } else {
                    const pos = this.getPosCenter(this.originalSourceData);
                    centerx = pos.center[0];
                    centery = pos.center[1];
                    zoom = this.getZoom(pos.min[0], pos.min[1], pos.max[0], pos.max[1]) + 4;
                }
                // 计算zoom
                series = [
                    {
                        name: '企业数',
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        symbol: 'image://' + svgIcon,
                        symbolOffset: [0, -40],
                        symbolSize: val => {
                            let num = [30, 38];
                            this.groupData.map((item, index) => {
                                item.items.map(v => {
                                    if (v.x_coordinate_point === val[0] && v.y_coordinate_point === val[1]) {
                                        num = [this.sourceHeight[index], this.sourceHeight[index] * 38 / 30];
                                    }
                                });
                            });
                            return num;
                        },
                        label: {
                            distance: 16,
                            normal: {
                                show: false,
                                formatter: value => {
                                    // let className = 'num';
                                    // this.groupData.map((item, index) => {
                                    //     item.items.map(v => {
                                    //         if (v.x_coordinate_point === value.value[0] && v.y_coordinate_point === value.value[1]) {
                                    //             className += this.fontSize[index];
                                    //         }
                                    //     });
                                    // });
                                    // const str = '{' + className + '|' + value.value[2] + '}\n' + value.name;
                                    const str = value.name;
                                    return str;
                                },
                                textStyle: {
                                    color: '#FFC61A',
                                    fontSize: 12,
                                },
                                align: 'center',
                                position: 'bottom',
                                rich: {
                                    num20: {
                                        color: '#3D3938',
                                        fontSize: 20,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    num24: {
                                        color: '#3D3938',
                                        fontSize: 24,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    num28: {
                                        color: '#3D3938',
                                        fontSize: 28,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                },
                            },
                        },
                        zlevel: 5,
                        data: this.sourceData,
                    },
                    {
                        type: 'map',
                        map: 'china',
                        geoIndex: 0,
                        aspectScale: 0.75,
                        tooltip: {
                            show: false,
                        },
                    },
                ];
                geo.zoom = zoom;
                geo.center = [centerx, centery];
            } else {
                series = [{
                    type: 'map',
                    map: 'china',
                    geoIndex: 0,
                    aspectScale: 0.75,
                    tooltip: {
                        show: false,
                    },
                },
                ];
                geo.zoom = zoom;
                geo.center = [ 108.6894231500, 32.9076680103 ];
            }
            const option = {
                backgroundColor: '#0C274F', // 地图背景色
                geo: geo,
                series: series,
            };
            if (this.sourceName) {
                option.series[0].label.normal.show = true;
            }
            myChart.setOption(option, true);
            this.sourceOpt = option;
            const that = this;
            function eConsole(param) {
                that.originalSourceData.map(item => {
                    if (item.x_coordinate_point === param.data.value[0] && item.y_coordinate_point === param.data.value[1]) {
                        that.sourceId = item.source_id;
                        that.currentSource = item;
                    }
                });
                that.isSingleSource = true;
                that.handleSupplyBase();

            }
            myChart.on('click', eConsole);
            myChart.on('georoam', () => {
                const { zoom, center } = myChart.getOption().geo[0];
                if (zoom > 7 && !this.sourceOpt.series[0].label.normal.show) {
                    this.sourceOpt.series[0].label.normal.show = true;
                    this.sourceOpt.geo.zoom = zoom;
                    this.sourceOpt.geo.center = center;
                    myChart.setOption(this.sourceOpt, true);
                } else if (zoom <= 7 && this.sourceOpt.series[0].label.normal.show) {
                    this.sourceOpt.series[0].label.normal.show = false;
                    this.sourceOpt.geo.zoom = zoom;
                    this.sourceOpt.geo.center = center;
                    myChart.setOption(this.sourceOpt, true);
                }
            });
        },

        // 单个货源地图表
        linesChart() {
            const svgIcon = require('../images/' + this.materials[this.activeIndex].fontClass + '.png');
            const myChart1 = this.$echarts.init(document.getElementById('singleChartMap'));
            // myChart1.resize();
            // eslint-disable-next-line max-len
            // const planePath = 'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z';
            const series = [];
            series.push({
                name: this.currentSource.short_name,
                type: 'lines',
                zlevel: 1,
                effect: {
                    show: true,
                    period: 8,
                    trailLength: 0,
                    symbolSize: 18,
                    symbol: 'image://' + require('@/assets/images/EBCHome/resizeApi.png'),
                },
                lineStyle: {
                    normal: {
                        color: '#1C98FB',
                        width: 2,
                        curveness: 0.2,
                    },
                },
                data: this.singleSourceChartData1,
            },
            {
                name: this.currentSource.short_name,
                type: 'lines',
                zlevel: 2,
                effect: {
                    show: true,
                    period: 8,
                    trailLength: 0,
                    symbolSize: 18,
                    symbol: 'image://' + require('@/assets/images/EBCHome/resizeApi.png'),
                },
                lineStyle: {
                    normal: {
                        color: '#FFC61A',
                        width: 2,
                        opacity: 1,
                        curveness: 0.2,
                    },
                },
                data: this.singleSourceChartData1,
            },
            {
                name: this.currentSource.short_name,
                type: 'effectScatter',
                coordinateSystem: 'geo',
                zlevel: 2,
                rippleEffect: {
                    brushType: 'stroke',
                },
                label: {
                    normal: {
                        show: true,
                        position: 'right',
                        formatter: '{b}',
                    },
                },
                symbol: 'circle',
                symbolSize: function (val) {
                    return val[2] / 8;
                },
                itemStyle: {
                    normal: {
                        color: '#FFC61A',
                    },
                },
                data: this.singleSourceChartData2,
            },
            {
                name: this.currentSource.short_name,
                type: 'scatter',
                coordinateSystem: 'geo',
                symbol: 'image://' + svgIcon,
                symbolSize: [30, 38],
                label: {
                    distance: 16,
                    normal: {
                        show: true,
                        position: 'bottom',
                        formatter: function (value) {
                            // const str = '{num|' + value.value[2] + '}\n' + value.name;
                            const str = value.name;
                            return str;
                        },
                        textStyle: {
                            color: '#fff',
                            fontSize: 12,
                        },
                        align: 'center',
                        rich: {
                            num: {
                                color: '#3D3938',
                                fontSize: 28,
                                fontFamily: 'QuartzLTStd',
                            },
                        },
                    },
                },
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#FCED7A', // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#F3BA12', // 100% 处的颜色
                        }], false), // 标志颜色
                    },
                },
                zlevel: 6,
                data: [{
                    name: this.currentSource.short_name,
                    value: [
                        Number(this.currentSource.x_coordinate_point),
                        Number(this.currentSource.y_coordinate_point),
                        this.currentSource.total_count,
                    ],
                }],
            }
            );
            const option = {
                backgroundColor: '#0C274F', // 地图背景色
                tooltip: {
                    show: false,
                    triggerOn: 'click',
                    enterable: true,
                },
                geo: {
                    map: 'china', // 表示中国地图
                    tooltip: {
                        show: true,
                        // position: ['36.5%', '-3%'],
                        position: point => {
                            return [point[0] - 420, point[1] - 430];
                        },
                        extraCssText: 'width:7rem;height:4rem;pointer-events:all!important;background-color:transparent',
                        formatter: () => {
                            return this.getSourceDetailHtml();
                        },
                    },
                    label: {
                        normal: {
                            show: true, // 是否显示对应地名
                            textStyle: { // 字体颜色
                                color: '#00cad5',
                            },
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                color: '#fff',
                            },
                        },
                    },
                    roam: true,
                    itemStyle: {
                        normal: {
                            borderWidth: 1, // 地图边框宽度
                            borderColor: '#0c4765', // 地图边框颜色
                            areaColor: '#011e3e', // 地图颜色
                        },
                        emphasis: {// 选中省份的颜色
                            areaColor: '#011e3e',
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowBlur: 0,
                            borderWidth: 1,
                            shadowColor: '#fff',
                        },
                    },
                    zoom: 15,
                    center: [
                        Number(this.currentSource.x_coordinate_point),
                        Number(this.currentSource.y_coordinate_point),
                    ],
                },
                series: series,
            };
            myChart1.setOption(option, true);
            // const showtip = () => {
            //     myChart1.dispatchAction({
            //         type: 'showTip',
            //         seriesIndex: 3,
            //         name: this.currentSource.short_name,
            //     });
            // };
            // myChart1.on('click', showtip);
            myChart1.dispatchAction({
                type: 'showTip',
                seriesIndex: 3,
                name: this.currentSource.short_name,
            });
        },

        // 获取货源地详情模板
        getSourceDetailHtml() {
            const html = `
                <div class="source-details">
                    <div class="close-btn" onclick="sourceCloseSingal()">
                        <span class="iconfont iconguanbi"></span>
                    </div>
                    <h4>
                        <p>${this.detail.short_name}</p>
                        <div class="light-beam"></div>
                    </h4>
                    <div class="row-con">
                        <div class="row-item">
                            <div class="flex-item">
                                <span class="iconfont iconlocation1"></span>
                                <span>货源地名称：${this.detail.short_name}</span>
                            </div>
                            <div class="flex-item">
                                <span class="iconfont iconqiye"></span>
                                <span>管理单位：${this.detail.org_name}</span>
                            </div>
                        </div>
                        <div class="row-item">
                            <div class="flex-item">
                                <span class="iconfont iconfenzu"></span>
                                <span>原材料分类：${this.currentMaterial.name}</span>
                            </div>
                            <div class="flex-item">
                                <span class="iconfont iconlifang"></span>
                                <span>年度供应总量：${this.detail.supplied_count}吨</span>
                            </div>
                        </div>
                        <div class="row-item">
                            <div class="flex-item">
                                <span class="iconfont iconqiye"></span>
                                <span>供应企业：${this.detail.tenant_shortname || ''}</span>
                            </div>
                            <div class="flex-item" onclick="sourceFlowAnalysis();">
                                <span class="iconfont iconlocation"></span>
                                <span style="text-decoration: underline;cursor: pointer">供应场站：${this.detail.supply_station.split(',').length}</span>
                            </div>
                        </div>
                        <div class="row-item">
                            <div class="flex-item">
                                <span class="iconfont iconshijian"></span>
                                <span>供应开始时间：${this.detail.first_supply_time}</span>
                            </div>
                            <div class="flex-item">
                                <span class="iconfont iconlifang"></span>
                                <span>状态：${this.detail.is_permit === '1' ? '在供' : '停供'}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btn-con">
                        <button onclick="sourceDetail();">
                            详情
                        </button>
                        <button onclick="sourceSupplyDetail();">
                            供货明细
                        </button>
                        <button onclick="sourceFlowAnalysis();">
                            流向分析
                        </button>
                    </div>
            </div>
            `;
            return html;
        },
    },
};
</script>
<style lang="stylus">
@import '~@/styles/mixins.styl'
.sourceAnalysis
    height 100%
    position relative
    z-index 9
    .el-loading-mask
        background-color: rgba(12,39,79,0.7);
    .time-con
        position absolute
        top .2rem
        left .2rem
        background rgba(0,57,120,0.5)
        box-shadow 1px 1px 4px 0px rgba(0,0,0,0.5)
        border-radius 2px;
        width 4.83rem
        line-height .6rem
        z-index 10
        padding 0.1rem;
        .time
            width 100%
            height 0.46rem
            color #fff
            text-align center
            font-size .16rem
            display flex
            align-items center
            .el-input__inner
                background rgba(0,57,120,0.5)
                font-size .16rem
                border 1px solid rgba(255,255,255,0.3)
                .el-range-input
                    background rgba(0,57,120,0.5)
                    font-size .16rem
                    color #fff
                .el-range-separator
                    color #fff
                    font-size .16rem
            .el-input
                width 3.5rem
                .el-input__inner
                    border-right 0
                    color #fff
                .el-input-group__append
                    background-color #0078bb
                    border #0078bb
                    margin-left -1px;
                    .search-btn
                        width 0.5rem
                        color #fff;
    .materials-classifiy
        right .2rem
        top .2rem
        position absolute
        z-index 10
        .item
            width 1.26rem
            height .6rem
            background url(./../images/bt_normal.png) no-repeat
            background-size 100% 100%
            margin-bottom .2rem
            cursor pointer
            padding-left .2rem
            line-height .6rem
            color #0078BB
            font-size .18rem
            &.active
                background url(./../images/bt_highlight.png) no-repeat
                background-size 100% 100%
                color #FFC61A
            span
                vertical-align middle
                margin-right .06rem
    .chartMap
        width 100%
        height 100%
    .source-details
        position absolute
        left .2rem
        top 1rem
        width 8rem
        background rgba(0,57,120,.6)
        border-radius .04rem
        min-height 2.88rem
        padding .2rem
        color #20B0FB
        font-size .16rem
        pointer-events: all
        &:after
            display block
            width 0
            height 0
            position absolute
            left 0
            right 0
            bottom: -0.2rem;
            margin auto
            content ''
            border-style solid
            border-width: 0.1rem;
            border-color rgba(0,57,120,0.6) transparent transparent
        .close-btn
            position absolute
            right .1rem
            top .1rem
            cursor pointer
            color #fff
        h4
            font-size .18rem
            color #fff
            line-height .27rem
            background linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(108,151,216,1) 100%)
            -webkit-background-clip text
            -webkit-text-fill-color transparent
            text-align center
            position relative
            p
                height .26rem
            .light-beam
                position absolute
                top .1rem
                width 100%
                background url(./../images/line.png) no-repeat
                height .47rem
                background-size 100% 100%
        .row-con
            margin-top .2rem
            .row-item
                color #20B0FB
                display flex
                margin-bottom .2rem
                line-height .2rem
                .flex-item
                    flex 1
                .iconfont
                    vertical-align middle
                    margin-right .05rem
        .btn-con
            margin-top .1rem
            display flex
            justify-content center
            button
                resetButton()
                width 1rem
                height .4rem
                background linear-gradient(135deg,rgba(58,150,254,1) 0%,rgba(64,182,255,1) 100%)
                border-radius 2px
                color #fff
                margin-right .4rem
</style>
